import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { ReactNode, Suspense, lazy } from "react";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga4";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { posts } from "./8k/Posts";
import {
  API_URL,
  Environment,
  GOOGLE_ANALYTICS_MEASUREMENT_ID as GOOGLE_ANALYTICS_MEASUREMENT_IDS,
  Site,
  getEnviroment,
  getSite,
  isEnvironment,
  isSite,
} from "./components/constants";

if (isEnvironment(Environment.MANUAL)) {
  // Fix autoreload locally:
  // https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/214#issuecomment-826732507
  import("./3d_ultrasound_studio/Main3dUltrasoundStudio");
  import("./8k/Main8k");
  import("./8k/RenderedPost");
  import("./components/Affiliate");
  import("./components/CropUltrasound");
  import("./components/EditResult");
  import("./components/EightKUltrasound");
  import("./components/FaceEditor");
  import("./components/FinishLogin");
  import("./components/Login");
  import("./components/Main");
  import("./components/Personalize");
  import("./components/Invite");
  import("./components/Prepay");
  import("./components/Privacy");
  import("./components/Refund");
  import("./components/SharedResults");
  import("./components/StudioFaq");
  import("./components/Unlock");
  import("./components/Free8K");
  import("./components/Workspace");
  import("./components/widgets/ScrollToTop");
  import("./components/ContestApplication");
  import("./components/Enhance");
  import("./components/Blog");
}

const Main3dUltrasoundStudio = lazy(
  () => import("./3d_ultrasound_studio/Main3dUltrasoundStudio")
);
const Main8k = lazy(() => import("./8k/Main8k"));
const RenderedPost = lazy(() => import("./8k/RenderedPost"));
const Affiliate = lazy(() => import("./components/Affiliate"));
const CropUltrasound = lazy(() => import("./components/CropUltrasound"));
const EditResult = lazy(() => import("./components/EditResult"));
const EightKUltrasound = lazy(() => import("./components/EightKUltrasound"));
const FaceEditor = lazy(() => import("./components/FaceEditor"));
const FinishLogin = lazy(() => import("./components/FinishLogin"));
const Login = lazy(() => import("./components/Login"));
const Main = lazy(() => import("./components/Main"));
const Personalize = lazy(() => import("./components/Personalize"));
const Invite = lazy(() => import("./components/Invite"));
const Prepay = lazy(() => import("./components/Prepay"));
const Privacy = lazy(() => import("./components/Privacy"));
const Refund = lazy(() => import("./components/Refund"));
const SharedResults = lazy(() => import("./components/SharedResults"));
const StudioFaq = lazy(() => import("./components/StudioFaq"));
const Unlock = lazy(() => import("./components/Unlock"));
const UserStudy = lazy(() => import("./components/Free8K"));
const Workspace = lazy(() => import("./components/Workspace"));
const Blog = lazy(() => import("./components/Blog"));
const BlogPost = lazy(() => import("./components/BlogPost"));
const ScrollToTop = lazy(() => import("./components/widgets/ScrollToTop"));
const ContestApplication = lazy(
  () => import("./components/ContestApplication")
);
const Enhance = lazy(() => import("./components/Enhance"));

interface MySuspenseProps {
  children: ReactNode;
}

export const MySuspense: React.FC<MySuspenseProps> = ({ children }) => {
  return <Suspense fallback={"Loading"}>{children}</Suspense>;
};

export const free8KUltrasoundUrl = "/free-8k-ultrasound";

function AppImpl() {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_IDS[getSite()], {
      testMode: isEnvironment(Environment.MANUAL),
    });
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: currentPath });
  }, [location]);

  return (
    <div>
      {!isEnvironment(Environment.PROD) && (
        <div
          style={{
            height: "50px",
            width: "100%",
            backgroundColor: isEnvironment(Environment.MANUAL)
              ? "green"
              : "red",
          }}
        >
          {getEnviroment()} (<Link to={API_URL}>debug</Link>,{" "}
          <Link to={API_URL + "debug/latest_uploads"}>latest uploads</Link>)
        </div>
      )}
      {false && (
        <Alert key="global" variant="warning">
          (15:28 Jun 13 New York time) Unfortunately all our graphic cards are
          down, we are working on fixing the issue.
        </Alert>
      )}
      <Routes>
        {isSite(Site.THREE_D_ULTRASOUND_STUDIO) && (
          <>
            <Route
              path="/"
              element={
                <MySuspense>
                  <Main3dUltrasoundStudio />{" "}
                </MySuspense>
              }
            />
          </>
        )}

        {isSite(Site.EIGHT_K) && (
          <>
            <Route
              path="/"
              element={
                <MySuspense>
                  <Main8k />{" "}
                </MySuspense>
              }
            />
            {posts.map((post, index) => (
              <Route
                key={index}
                path={post.url}
                element={
                  <MySuspense>
                    <RenderedPost post={post} />{" "}
                  </MySuspense>
                }
              />
            ))}
          </>
        )}

        {isSite(Site.REALVIEW) && (
          <>
            <Route path="/" element={<MySuspense>"realview"</MySuspense>} />
          </>
        )}

        {isSite(Site.PHOTOREALISTIC) && (
          <>
            <Route
              path="/"
              element={
                <MySuspense>
                  <Main studio={false} />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/affiliate"
              element={
                <MySuspense>
                  <Affiliate />{" "}
                </MySuspense>
              }
            />
            <Route
              path={free8KUltrasoundUrl}
              element={
                <MySuspense>
                  <UserStudy />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/studio-faq"
              element={
                <MySuspense>
                  <StudioFaq />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/studio"
              element={
                <MySuspense>
                  <Main studio={true} />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/privacy"
              element={
                <MySuspense>
                  <Privacy />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/login"
              element={
                <MySuspense>
                  <Login />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/workspace"
              element={
                <MySuspense>
                  <Workspace />
                </MySuspense>
              }
            />
            <Route
              path="/contest/apply/:jobId"
              element={
                <MySuspense>
                  <ContestApplication />
                </MySuspense>
              }
            />
            <Route
              path="/personalize/:jobId"
              element={
                <MySuspense>
                  <Personalize />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/edit/:resultId"
              element={
                <MySuspense>
                  <EditResult />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/enhance/:jobId"
              element={
                <MySuspense>
                  <Enhance />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/results/:sharedToken"
              element={
                <MySuspense>
                  <SharedResults />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/invite/:inviteCode"
              element={
                <MySuspense>
                  <Invite />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/unlock/:jobId"
              element={
                <MySuspense>
                  <Unlock />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/refund/:jobId"
              element={
                <MySuspense>
                  <Refund />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/prepay"
              element={
                <MySuspense>
                  <Prepay />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/login/finish/:secretToken"
              element={
                <MySuspense>
                  <FinishLogin />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/editor/"
              element={
                <MySuspense>
                  <FaceEditor />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/crop_3d_ultrasound/"
              element={
                <MySuspense>
                  <CropUltrasound />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/8k-ultrasound"
              element={
                <MySuspense>
                  <EightKUltrasound />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/blog"
              element={
                <MySuspense>
                  <Blog />{" "}
                </MySuspense>
              }
            />
            <Route
              path="/blog/:slug"
              element={
                <MySuspense>
                  <BlogPost />{" "}
                </MySuspense>
              }
            />
          </>
        )}
      </Routes>
    </div>
  );
}

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div style={{ textAlign: "center" }}>
        <Router>
          <ScrollToTop />
          <AppImpl />
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;
